// ARQUIVO PARA DECLARAÇÕES DE FUNÇÕES UTILITÁRIAS NO NAMESPACE NXERP
frappe.provide('nxerp');

/**
 * Retorna uma string contendo apenas os números da string recebida por parâmetro.
 * @param {string} str - A string a ser processada.
 * @returns {string} - A string contendo apenas os números.
 */
nxerp.getStringNumbers = (str = '') => str.replace(/[^\d]/g, '');

/**
 * Arredonda uma lista de valores para um número específico de casas decimais.
 * @param {Array<number|string>} values - A lista de valores a serem arredondados.
 * @param {number} [digits=2] - O número de casas decimais para arredondar. Padrão é 2.
 * @returns {Array<number>} - A lista de valores arredondados.
 */
nxerp.roundValues = (values = [], digits = 2) => {
    console.log('Executando método "roundValues"...');
    console.log('Input (values):', values);
    console.log('Input (digits):', digits);

    const rounded_values = [];
    let value_diff = 0;

    values.forEach(value => {
        const number_value = typeof value === 'string' ? Number(utils.getStringNumbers(value)) : value;
        const rounded = Number(number_value.toFixed(digits));
        value_diff += number_value - rounded;
        rounded_values.push(rounded);
    });

    if (rounded_values.length > 0) {
        const lastIndex = rounded_values.length - 1;
        rounded_values[lastIndex] = Number((rounded_values[lastIndex] + value_diff).toFixed(digits));
    }

    console.log('Output:', rounded_values);
    return rounded_values;
};

/**
 * Nome padrão de grupo de botões de funções gerais ("Ações").
 */
nxerp.actions_group_btn_label = 'Ações';

/**
 * Abre um PDF em uma nova aba do navegador.
 * @param {string} pdf - A string base64 representando o conteúdo do PDF.
 */
nxerp.openPDF = (pdf) => {
    const binaryData = atob(pdf);
    const byteArray = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
};

nxerp.get_meta_fields = async (doctype, recursive_to_childs = false, properties = [], remove_breaks = true) => {
    return (await frappe.call('nxerp.utils.published.framework.get_meta_fields', {
        doctype, properties, recursive_to_childs, remove_breaks
    })).message;
};

window.show_relation_map = async (frm) => {
    frm.add_custom_button('Mapa de Relações', async () => {
        const doctype = frm.doc.doctype;
        const docname = frm.doc.name;
        const response = await frappe.call('nxerp.utils.get_docs_ref.get_docs', { doctype: doctype, docname: docname });

        if ($('div#mapaderelacoes').length) {
            $('div#mapaderelacoes').remove();
        }

        const d = new frappe.ui.Dialog({
            'title': 'Mapa de Relações',
            'fields': [
                {
                    'fieldname': 'teste',
                    'fieldtype': 'HTML',
                    'options': '<div id="mapaderelacoes" style="width: 100%; height: 100%;"></div>',
                },
            ],
        });
        d.show();

        // Ajusta o tamanho do diálogo dinamicamente com base na altura da tela
        const adjustDialogSize = () => {
            const maxHeight = window.innerHeight * 0.8; // 90% da altura da tela
            const maxWidth = window.innerWidth * 0.9; // 90% da largura da tela
            d.$wrapper.find('.modal-dialog').css({
                'max-width': `${maxWidth}px`,
                'max-height': `${maxHeight}px`,
            });
            d.$wrapper.find('.modal-content div.section-body, div.section-head').css({
                'max-width': '100% !important',
                'margin': '0',
                'height': `${maxHeight}px`,
            });
            d.$wrapper.find('#mapaderelacoes').css({
                'height': `${maxHeight - 50}px`, // Ajusta a altura do conteúdo do mapa
            });
        };

        adjustDialogSize(); // Ajusta o tamanho inicialmente
        window.addEventListener('resize', adjustDialogSize); // Ajusta o tamanho ao redimensionar a janela

        setTimeout(() => {
            const html = document.getElementById('mapaderelacoes');
            frappe.nxerp.cytoscape(html, response.message);
        }, 200);
    }, nxerp.actions_group_btn_label);
};

window.force_filter_on_grid = function (grid) {
    if (!grid._original_refresh) {
        grid._original_refresh = grid.refresh;
        grid.refresh = function () {
            // Executa o refresh original
            this._original_refresh.apply(this, arguments);

            // Força a exibição do filtro
            if (this.header_search) {
                this.header_search.show_search = true;
                this.header_search.show_search_row = () => true;
            }
            this.filter_applied = true;
            this.make_head();
        };
    }
    // Executa o refresh para aplicar as alterações
    grid.refresh();
};

(function () {
    // Armazena a função original do refresh do formulário
    const original_refresh = frappe.ui.form.Form.prototype.refresh;

    frappe.ui.form.Form.prototype.refresh = function () {
        // Executa o refresh original
        original_refresh.apply(this, arguments);

        // Verifica se o formulário possui o field 'table_documento_de_origem'
        if (this.fields_dict['table_documento_de_origem']) {
            let grid = this.fields_dict['table_documento_de_origem'].grid;
            if (grid) {
                force_filter_on_grid(grid, this.fields_dict['table_documento_de_origem']);
            };

            let frm = this.fields_dict['table_documento_de_origem'].frm;
            if (frm) {
                setTimeout(() => {
                    show_relation_map(frm);
                }, 200);
            }
        }
    };
})();

// Função principal para adicionar o botão em qualquer DocType
nxerp.adicionar_botao_pre_lancamento = function (frm) {

    const label = 'Pré Lançamento Contábil';
    const grupo = nxerp.actions_group_btn_label;

    frm.add_custom_button(__(label), function () {
        if (frm.is_dirty()) {
            frappe.toast({ message: "Para visualizar o Pré Lançamento Contábil é necessário salvar o documento.", indicator: "yellow" })
            return
        }
        nxerp.abrir_dialogo_lancamento(frm);
    }, grupo);
};


// Função que abre o diálogo
nxerp.abrir_dialogo_lancamento = function (frm) {
    try {
        // Primeiro, carrega os dados do backend e verifica se estão corretos
        frappe.call({
            method: "nxerp.utils.published.contabilidade.get_pre_visualizacao_lancamentos_contabeis",
            args: {
                doctype: frm.doctype,
                doc_name: frm.doc.name
            },
            callback: function (response) {
                try {
                    debugger
                    if (!response.message) {
                        throw new Error("Não foi possível pré-visualizar o lançamento, pois está faltando informações relacionadas às contas contábeis.");
                    }
                    if (response.message == "Não será gerado lançamento contábil para este Documento") {
                        throw new Error("Não será gerado lançamento contábil para este Documento");
                    }
                    const dados = response.message;

                    // Verifica se o conteúdo existe e tem dados válidos
                    if (!dados.conteudo || !Array.isArray(dados.conteudo) || dados.conteudo.length === 0) {
                        throw new Error("Não foi possível pré-visualizar o lançamento, pois está faltando informações relacionadas às contas contábeis. Não há itens para exibir.");
                    }

                    // Verifica se existem contas contábeis associadas
                    const faltamContas = dados.conteudo.some(item => !item.conta);
                    if (faltamContas) {
                        throw new Error("Não foi possível pré-visualizar o lançamento, pois está faltando informações relacionadas às contas contábeis.");
                    }

                    // Após validar os dados, obtém os campos e cria o diálogo
                    nxerp.obter_campos_lancamento_contabil_conteudo(function (campos_tabela) {
                        // Cria um novo diálogo
                        const dialog = new frappe.ui.Dialog({
                            title: 'Informações do Lançamento Contábil',
                            fields: [
                                {
                                    fieldname: 'info_section',
                                    fieldtype: 'Section Break',
                                    label: 'Informações Gerais'
                                },
                                {
                                    fieldname: 'data_de_emissao',
                                    fieldtype: 'Date',
                                    label: 'Data de Emissão',
                                    read_only: 1
                                },
                                {
                                    fieldname: 'empresa',
                                    fieldtype: 'Link',
                                    label: 'Empresa',
                                    options: 'Company',
                                    read_only: 1
                                },
                                {
                                    fieldname: 'currency',
                                    fieldtype: 'Link',
                                    label: 'Moeda da empresa',
                                    options: 'Currency',
                                    read_only: 1
                                },
                                {
                                    fieldname: 'column_break_1',
                                    fieldtype: 'Column Break'
                                },
                                {
                                    fieldname: 'doctype_origem',
                                    fieldtype: 'Link',
                                    label: 'Documento de Origem',
                                    options: 'DocType',
                                    read_only: 1
                                },
                                {
                                    fieldname: 'origem',
                                    fieldtype: 'Dynamic Link',
                                    label: 'Origem',
                                    options: 'doctype_origem',
                                    read_only: 1
                                },
                                {
                                    fieldname: 'observacao',
                                    fieldtype: 'Data',
                                    label: 'Observações',
                                    read_only: 1
                                },
                                {
                                    fieldname: 'conteudo_section',
                                    fieldtype: 'Section Break',
                                    label: 'Conteúdo'
                                },
                                {
                                    fieldname: 'conteudo',
                                    fieldtype: 'Table',
                                    label: 'Conteúdo',
                                    options: 'Lancamento Contabil Conteudo',
                                    read_only: 1,
                                    cannot_add_rows: true,
                                    hide_delete: true,
                                    fields: campos_tabela
                                }
                            ],
                            primary_action_label: __('Sair'),
                            primary_action: function () {
                                dialog.hide();
                            }
                        });

                        // Preenche os campos do diálogo com os dados
                        dialog.set_values({
                            'data_de_emissao': dados.data_de_emissao || frm.doc.data_de_emissao,
                            'empresa': dados.empresa || frm.doc.empresa,
                            'currency': dados.currency || frm.doc.currency,
                            'doctype_origem': dados.doctype_origem || frm.doctype,
                            'origem': dados.origem || frm.doc.name,
                            'observacao': dados.observacao || ''
                        });

                        // Garante que cada linha tenha um ID único para evitar erros
                        const conteudo_data = dados.conteudo.map((item, idx) => ({
                            ...item,
                            name: item.name || `row-${idx + 1}`,
                            idx: idx + 1
                        }));

                        dialog.fields_dict.conteudo.grid.df.data = conteudo_data;
                        dialog.fields_dict.conteudo.grid.refresh();

                        // adicionar titulo nos links de conta e entidade.
                        dados.conteudo.forEach((item) => {
                            if (item.conta && item.conta_titulo) {
                                frappe.utils.add_link_title("Plano de Contas", item.conta, item.conta_titulo);
                            }
                            if (item.entidade && item.entidade_nome) {
                                frappe.utils.add_link_title("Entity", item.entidade, item.entidade_nome);
                            }
                        });

                        // Ajustar tamanho do diálogo
                        dialog.$wrapper.find('.modal-dialog').css('max-width', '90%');
                        dialog.show();
                        setTimeout(() => {
                            dialog.fields_dict.conteudo.grid.refresh();
                        }, 150);
                    });
                } catch (innerError) {
                    // Exibe mensagem de erro
                    frappe.show_alert({ message: innerError.message || "Não foi possível pré-visualizar o lançamento, pois está faltando informações relacionadas às contas contábeis.", indicator: 'yellow' });
                }
            },
            error: function (xhr, textStatus, errorThrown) {
                frappe.show_alert({ message: `Não foi possível pré-visualizar o lançamento, pois está faltando informações relacionadas às contas contábeis.<br> Retorno: ${xhr.exception}`, indicator: 'red' });
            }
        });
    } catch (outerError) {
        // Exibe mensagem de erro
        frappe.show_alert({ mensagem: "Não foi possível pré-visualizar o lançamento, pois está faltando informações relacionadas às contas contábeis.", indicator: 'red' });
    }
};


// Função para obter os campos do DocType
nxerp.obter_campos_lancamento_contabil_conteudo = function (callback) {
    frappe.model.with_doctype('Lancamento Contabil Conteudo', function () {
        const meta = frappe.get_meta('Lancamento Contabil Conteudo');

        const campos_para_nao_mostrar = ['conta_controle', 'conta_caixa', 'saldo_anterior', 'saldo_posterior', 'conta_nome'];

        // Filtra campos de section breaks e column breaks
        let fields = meta.fields.filter(field => {
            return field.fieldtype !== 'Section Break' &&
                field.fieldtype !== 'Column Break' &&
                !campos_para_nao_mostrar.includes(field.fieldname);
        });

        // Mapeia os campos para ter formato adequado para o grid
        fields = fields.map(field => {
            // Cria uma cópia do campo sem referências ao objeto original
            const field_copy = {
                fieldname: field.fieldname,
                fieldtype: field.fieldtype,
                label: field.label,
                read_only: 1,
                hidden: field.hidden,
                columns: field.columns ? field.columns : 2
            };

            // Adiciona options se existir
            if (field.options) {
                field_copy.options = field.options;
            }

            // Adiciona in_list_view para os campos importantes
            if (field.in_list_view) {
                field_copy.in_list_view = 1;
            }

            return field_copy;
        });

        // Definir quais campos aparecem na visualização de lista por padrão
        const list_view_fields = ['conta', 'debito', 'credito', 'obs', 'entidade'];

        fields.forEach(field => {
            if (list_view_fields.includes(field.fieldname) || field.fieldname.includes('centro_de_custo')) {
                field.in_list_view = 1;
            }
        });

        callback(fields);
    });
};
