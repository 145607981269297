class Dialog {
    public prompt: any;
    public type: string;

    constructor({
        type = "dialog",
    }) {
        console.log('Dialog constructor');
        this.type = type;
    }

    build() {
        if (this.type === "dialog") {
            return this.buildDefaultDialog();
        }
    }

    buildDefaultDialog() {
        console.log('Dialog DefaultDialog');
    }

    buildConfirmDialog() {
        console.log('Dialog ConfirmDialog');
    }

    buildAddMultipleDialog() {
        console.log('Dialog ConfirmDialog');
    }

    show() {
        console.log('Dialog open');
    }

    hide() {
        console.log('Dialog close');
    }
}

frappe.provide("frappe.nxerp.dialog");
frappe.nxerp.Dialog = Dialog;

export default Dialog;
