// ARQUIVO PARA DECLARAÇÕES DE LISTENERS DE EVENTOS DE REALTIME

window.addEventListener('load', () => {
    frappe.realtime.on('toast', (params) => {
        console.log('Executando evento de toast\nParâmetros recebidos:', params);

        const indicator = params.indicator || 'blue';
        const message = params.message || 'Defina a mensagem do alerta na chave "message"';
        const seconds = params.seconds || 5;
        const user = params.user || frappe.session.user;
        const to_all_users = params.to_all_users || false;

        if (!to_all_users && user !== frappe.session.user) return;

        return frappe.toast({
            indicator,
            message,
        }, seconds);
    })

    frappe.realtime.on('progress_nxpro', (params) => {
        console.log('Executando evento de progress\nParâmetros recebidos:', params);

        const message = params.description || 'Defina a mensagem do alerta na chave "message"';
        const user = params.user || frappe.session.user;
        const to_all_users = params.to_all_users || false;
        const percent = params.percent || 0;
        const title = params.title || 'Progresso';
        if (!to_all_users && user !== frappe.session.user) return;

        return frappe.show_progress(title, percent, 100, message, true);
    })
});
