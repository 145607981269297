frappe.provide('frappe.aps.highlight');

frappe.aps.highlight = (function() {
    // Variável para armazenar o item selecionado atualmente
    let selectedItemId = null;

    // Configurar o evento de seleção
    function setupSelectionEvent(ctx) {
        if (!ctx || !ctx.timeline) {
            console.warn("Contexto da timeline inválido");
            return;
        }
        
        ctx.timeline.on('select', function (properties) {
            if (properties.items && properties.items.length > 0) {
                var itemId = properties.items[0];
                // Armazenar o ID do item selecionado
                selectedItemId = itemId;
            } else {
                // Se não houver itens selecionados, limpar seleção
                selectedItemId = null;
            }
        });
        
        // Adicionar double-click para itens da timeline
        ctx.timeline.on('doubleClick', function (properties) {
            if (properties.item && properties.item.startsWith('op-')) {
                var item = ctx.timelineItems.get(properties.item);
                if (item) {
                    // Mostrar diálogo do Frappe com informações
                    showItemDialog(item);
                }
            }
        });
    }

    // Função para lidar com a seleção de um item
    function handleSelectedItem(ctx) {
        if (!selectedItemId) {
            frappe.msgprint('Nenhum item selecionado. Selecione um item na timeline primeiro.');
            return;
        }
        
        var item = ctx.timelineItems.get(selectedItemId);
        
        if (item && item.id.startsWith('op-')) {
            const isHighlighted = item.style &&
                (item.style.includes('border: 2px solid #000'));
            
            if (!isHighlighted) {
                highlightRelatedOperations(selectedItemId, ctx);
                
                if (ctx.arrows.hideAll) {
                    ctx.arrows.hideAll();
                }
                
                order = item.order;
                ctx.arrows.showByOrder(order);
            }
        }
    }

    // Função para mostrar o diálogo do Frappe com informações do item
    function showItemDialog(item) {
        frappe.prompt([
            {
                fieldname: 'item_info',
                fieldtype: 'HTML',
                options: `
                    <div>
                        <h4>Informações do Item</h4>
                        <p><strong>ID:</strong> ${item.id}</p>
                        <p><strong>Ordem:</strong> ${item.order || 'N/A'}</p>
                        <p><strong>Conteúdo:</strong> ${item.content || 'N/A'}</p>
                        <!-- Adicione outras informações relevantes aqui -->
                    </div>
                `
            }
        ],
        function(values) {
            // Callback após fechar o diálogo (se necessário)
        },
        'Detalhes da Operação',
        'Fechar'
        );
    }

    // Função para mostrar diálogo do item selecionado
    function showSelectedItemInfo(ctx) {
        if (!selectedItemId) {
            frappe.msgprint('Nenhum item selecionado. Selecione um item na timeline primeiro.');
            return;
        }
        
        var item = ctx.timelineItems.get(selectedItemId);
        if (item) {
            showItemDialog(item);
        }
    }
    
    function resetHighlight(ctx) {
        if (!ctx || !ctx.timelineItems) {
            console.warn("Contexto da timeline inválido para resetar destaques");
            return;
        }
        
        const allItems = ctx.timelineItems.get();
        const originalItems = [...allItems]; 
        for (let i = 0; i < originalItems.length; i++) {
            const item = originalItems[i];
            
            if (item.id.startsWith('op-')) {
                const baseStyle = item.style
                    .replace('opacity: 0.3;', '')
                    .replace('opacity: 1;', '')
                    .replace('border: 2px solid #000;', '')
                    .replace('border: 2px solid #ff4500;', '');
        
                originalItems[i] = {
                    id: item.id,
                    style: baseStyle
                };
            }
        }
        ctx.timelineItems.update(originalItems);
        hideAllArrows(ctx);
    }
    
    function hideAllArrows(ctx) {
        try {
            if (ctx.dependencyArrows && ctx.dependencyArrows.length > 0) {
                ctx.dependencyArrows.forEach(a => {
                    if (a.arrow) {
                        try {
                            if (ctx.arrowsInstance) {
                                ctx.arrowsInstance.removeArrow(a.arrow);
                            }
                            a.arrow = null;
                        } catch (e) {
                            console.error("Erro ao esconder setas:", e);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Erro ao esconder setas:", error);
        }
    }
    
    function highlightSameOrder(orderName, ctx) {
        if (!orderName || !ctx || !ctx.timelineItems) return;
    
        const allItems = ctx.timelineItems.get();
        const updatedItems = [...allItems]; // Cria uma cópia do array
    
        // Loop de trás para frente
        for (let i = allItems.length - 1; i >= 0; i--) {
            const item = allItems[i];
            
            // Se não começar com 'op-', sai do loop
            if (!item.id.startsWith('op-')) {
                break;
            }
            
            // Cria um estilo base limpo removendo propriedades que vamos manipular
            let baseStyle = (item.style || '')
                .replace(/opacity:\s*[^;]+;/g, '')
                .replace(/border:\s*2px\s+solid\s+#[0-9a-f]{3,6};/g, '')
                .trim();
            
            // Remove espaços e ponto-e-vírgulas duplicados
            baseStyle = baseStyle.replace(/;;/g, ';');
            if (baseStyle.endsWith(';')) {
                baseStyle = baseStyle.slice(0, -1);
            }
            
            // Adiciona ponto-e-vírgula ao final se não estiver vazio
            if (baseStyle && !baseStyle.endsWith(';')) {
                baseStyle += ';';
            }
            
            // Aplica o estilo apropriado
            if (item.order === orderName) {
                updatedItems[i] = {
                    id: item.id,
                    style: baseStyle + (baseStyle ? ' ' : '') + 'border: 2px solid #000;'
                };
            } else {
                updatedItems[i] = {
                    id: item.id,
                    style: baseStyle + (baseStyle ? ' ' : '') + 'opacity: 0.3;'
                };
            }
        }
    
        ctx.timelineItems.update(updatedItems);
    }
    
    function highlightRelatedOperations(itemId, ctx) {
        const item = ctx.timelineItems.get(itemId);
        if (!item) return;
    
        if (item && item.order) {
            console.log("Destacando por ordem");
            hideAllArrows(ctx);
            highlightSameOrder(item.order, ctx);
            
            frappe.show_alert({
                message: __(`Exibindo operações da ordem ${item.order}`),
                indicator: 'blue'
            }, 3);
        }
    }
    
    return {
        setupSelectionEvent: setupSelectionEvent,
        resetHighlight: resetHighlight,
        highlightRelatedOperations: highlightRelatedOperations,
        highlightSameOrder: highlightSameOrder,        
        handleSelectedItem: handleSelectedItem,
        showSelectedItemInfo: showSelectedItemInfo,
        getCurrentSelection: function() { return selectedItemId; }    
    };
})();