frappe.provide('frappe.aps.timeline');

frappe.aps.timeline = (function() {
    let _container = null;
    let _timelineSection = null;
    
    function init(page) {
        setupContainer(page);        
    }
    
    function setupContainer(page) {
        // Adicionar seção para a timeline
        _timelineSection = $('<div class="timeline-section"></div>').appendTo(page.main);
        
        // HTML com estilos inline
        const timelineHtml = `   
          <div class="timeline-controls" style="margin-bottom: 10px;">
              <button id="btn-ant-14dias" class="btn btn-sm btn-default">← -14dias</button>
              <button id="btn-ant-7dias" class="btn btn-sm btn-default">← -7dias</button>
              <button id="btn-hoje" class="btn btn-sm btn-default">Hoje</button>
              <button id="btn-prox-7dias" class="btn btn-sm btn-default">+ 7dias →</button>
              <button id="btn-prox-14dias" class="btn btn-sm btn-default">+ 14dias →</button>
          </div>
          
          <div id="combined_timeline" class="timeline-container"></div>
        `;
        
        _timelineSection.html(timelineHtml);
        _container = document.getElementById('combined_timeline');
        
        // Aplicar estilos CSS básicos
        applyTimelineStyles();
    }
    
    function applyTimelineStyles() {
        const style = document.createElement('style');
        style.textContent = `
            /* Reset para estilos básicos */
            #combined_timeline * {
                box-sizing: border-box;
            }
            
            /* Container principal */
            .timeline-container {
                width: 100%;
                min-height: 350px;
                max-height: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                border: 1px solid #e0e0e0;
                background-color: #fff;
                margin-top: 10px;
            }
            
            /* Estilos base da timeline */
            .vis-timeline {
                border: none !important;
                background-color: #ffffff !important;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            }
            
            /* Área dos labels dos recursos */
            .vis-labelset {
                background-color: #f9f9f9;
                border-right: 1px solid #ddd;
                box-shadow: 2px 0 4px rgba(0,0,0,0.1);
            }
            
            .vis-labelset .vis-label {
                border-bottom: 1px solid #e0e0e0;
                font-weight: 500;
                padding: 5px 10px;
            }
            
            /* Estilos dos itens */
            .vis-item {
                border-width: 1px;
                font-size: 12px;
            }
            
            .vis-item.vis-background {
                border: none;
            }
            
            .vis-item.vis-selected {
                border-color: black;
                z-index: 10;
            }
            
            /* Ajustes de paineis */
            .vis-panel {
                border: none !important;
            }
            
            .vis-panel.vis-center,
            .vis-panel.vis-left,
            .vis-panel.vis-right {
                border: none;
            }
            
            .vis-panel.vis-top,
            .vis-panel.vis-bottom {
                background-color: transparent;
                border: none;
            }
            
            /* Eixo do tempo */
            .vis-time-axis .vis-text {
                font-size: 11px;
                color: #555;
            }
            
            /* Melhorar o visual dos itens */
            .vis-item .vis-item-content {
                padding: 2px 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            /* Fundo uniforme para todos os elementos */
            .vis-odd, .vis-even {
                background-color: #fff;
            }
            
            /* Garantir que cabeçalho seja branco */
            .vis-panel.vis-top {
                background-color: #ffffff !important;
            }
        `;
        document.head.appendChild(style);
    }
    
    function initializeTimeline(data) {
        try {

            // Se já existe uma timeline, destrua-a primeiro
            if (frappe.aps.timelineContext && frappe.aps.timelineContext.timeline) {
                try {
                    // Remover eventos da timeline existente
                    frappe.aps.timelineContext.timeline.off('select');
                    
                    // Destruir a timeline existente
                    frappe.aps.timelineContext.timeline.destroy();
                } catch (e) {
                    console.warn(`Erro ao destruir timeline anterior:`, e);
                }
            }
            
            // Limpar o contêiner da timeline
            if (_container) {
                _container.innerHTML = '';
            }        
            // Adicionar indicador de carregamento
            // _container.innerHTML = '<div class="timeline-loading" style="padding: 20px; text-align: center; font-weight: bold;">Carregando timeline... <span id="timeline-progress">0%</span></div>';
            
            // Cria os DataSets para os grupos (todos de uma vez já que são poucos)
            var timelineGroups = new nxerp.vis_data.DataSet(data.groups);
            
            // Criar um DataSet vazio para os itens inicialmente
            var timelineItems = new nxerp.vis_data.DataSet([]);

            const timelineOptions = data.options || {};
    
            // Instancia a timeline com o DataSet vazio
            var timeline = new nxerp.vis_time.Timeline(_container, timelineItems, timelineGroups, timelineOptions);
    
            // Inicialização do TimelineArrows
            const dependencies = data.dependencies || [];
            const arrowsInstance = new nxerp.TimelineArrows(timeline, [], {});
    
            // Array para armazenar as referências das setas
            let dependencyArrows = [];
    
            // Inicializar as referências das setas com as dependências do backend
            if (dependencies && dependencies.length > 0) {
                dependencies.forEach(dep => {
                    try {
                        // Armazenar referência da seta sem adicioná-la ainda
                        dependencyArrows.push({
                            id: dep.id,
                            id_item_1: dep.id_item_1,
                            id_item_2: dep.id_item_2
                        });
                    } catch (error) {
                        console.error(`Erro ao registrar seta:`, error);
                    }
                });
            }

            // Criar objeto de contexto que conterá todas as referências necessárias
            const ctx = {
                timeline: timeline,
                timelineItems: timelineItems,
                timelineGroups: timelineGroups,
                container: _container,
                data: data,
                dependencies: dependencies,
                dependencyArrows: dependencyArrows,
                arrowsInstance: arrowsInstance,
                currentGroupHeight: 30,
                
                // Mapa para rastrear setas visíveis
                visibleArrows: new Map(),
                
                // Métodos para gerenciamento de setas
                arrows: {
                    // Ocultar todas as setas
                    hideAll: function() {
                        ctx.dependencyArrows.forEach(dep => {
                            if (ctx.visibleArrows.get(dep.id)) {
                                try {
                                    ctx.arrowsInstance.removeArrow(dep.id);
                                    ctx.visibleArrows.set(dep.id, false);
                                } catch (error) {
                                    console.error(`Erro ao ocultar seta:`, error);
                                }
                            }
                        });
                        return ctx; // Para encadeamento de métodos
                    },
                    
                    showByOrder: function(orderName) {
                        
                        if (!orderName) {
                            return ctx;
                        }
                        
                        // Verificar se temos acesso aos itens do timeline
                        if (!ctx.timelineItems || typeof ctx.timelineItems.get !== 'function') {
                            return ctx;
                        }
                        
                        // Obter todos os itens do timeline
                        const allItems = ctx.timelineItems.get();
                        
                        // Primeiro, encontre todos os itens desta ordem
                        const orderItems = allItems.filter(item => {
                            // Log detalhado para entender a estrutura dos itens
                            if (item.id && item.id.startsWith('op-')) {
                                const orderValue = extractOrderValue(item);
                                return orderValue === orderName;
                            }
                            return false;
                        });
                        
                        if (orderItems.length === 0) {
                            return ctx;
                        }
                        
                        // Obtenha os IDs dos itens desta ordem
                        const orderItemIds = orderItems.map(item => item.id);
                        
                        // Verificar se temos dependências
                        if (!ctx.dependencyArrows || !Array.isArray(ctx.dependencyArrows)) {
                            return ctx;
                        }                        
                        
                        // Filtrar setas que conectam itens desta ordem
                        const orderArrows = ctx.dependencyArrows.filter(dep => 
                            orderItemIds.includes(dep.id_item_1) && orderItemIds.includes(dep.id_item_2)
                        );
                        
                        if (orderArrows.length === 0) {
                            return ctx;
                        }
                        
                        // Verificar instance do TimelineArrows
                        if (!ctx.arrowsInstance) {
                            return ctx;
                        }
                        
                        // Mostrar setas desta ordem
                        let addedCount = 0;
                        let errorCount = 0;
                        
                        orderArrows.forEach(dep => {
                            try {
                                if (!ctx.visibleArrows.get(dep.id)) {
                                    ctx.arrowsInstance.addArrow(dep);
                                    ctx.visibleArrows.set(dep.id, true);
                                    addedCount++;
                                }
                            } catch (error) {
                                console.error(`Erro ao mostrar seta: ${dep.id}`, error);
                                errorCount++;
                            }
                        });                        
                        
                        return ctx; // Para encadeamento de métodos
                    },
                    
                    // Alternar visibilidade das setas de uma ordem
                    toggleByOrder: function(orderName) {
                        if (!orderName) return ctx;
                        
                        // Encontre todos os itens desta ordem
                        const orderItems = ctx.timelineItems.get().filter(item => {
                            const itemData = item.data || {};
                            return itemData.order === orderName;
                        });
                        
                        // Obtenha os IDs dos itens desta ordem
                        const orderItemIds = orderItems.map(item => item.id);
                        
                        // Filtre as setas relacionadas a esta ordem
                        const orderArrows = ctx.dependencyArrows.filter(dep => 
                            orderItemIds.includes(dep.id_item_1) && orderItemIds.includes(dep.id_item_2)
                        );
                        
                        // Verifique se alguma seta desta ordem está visível
                        const anyVisible = orderArrows.some(arrow => ctx.visibleArrows.get(arrow.id));
                        
                        if (anyVisible) {
                            // Se alguma estiver visível, oculte todas desta ordem
                            orderArrows.forEach(arrow => {
                                if (ctx.visibleArrows.get(arrow.id)) {
                                    try {
                                        ctx.arrowsInstance.removeArrow(arrow.id);
                                        ctx.visibleArrows.set(arrow.id, false);
                                    } catch (error) {
                                        console.error(`Erro ao ocultar seta da ordem ${orderName}:`, error);
                                    }
                                }
                            });
                        } else {
                            // Se nenhuma estiver visível, mostre todas desta ordem
                            orderArrows.forEach(arrow => {
                                try {
                                    ctx.arrowsInstance.addArrow(arrow);
                                    ctx.visibleArrows.set(arrow.id, true);
                                } catch (error) {
                                    console.error(`Erro ao mostrar seta da ordem ${orderName}:`, error);
                                }
                            });
                        }
                        
                        return ctx; // Para encadeamento de métodos
                    },
                    
                    // Listar ordens disponíveis no timeline
                    getAvailableOrders: function() {
                        const orders = new Set();
                        
                        ctx.timelineItems.get().forEach(item => {
                            const itemData = item.data || {};
                            if (itemData.order) {
                                orders.add(itemData.order);
                            }
                        });
                        
                        return Array.from(orders);
                    },
                    
                    // Limpar setas
                    cleanup: function() {
                        this.hideAll();
                        ctx.dependencyArrows = [];
                        ctx.visibleArrows.clear();
                        return ctx; // Para encadeamento de métodos
                    },
                    
                    // Inicializar as setas
                    init: function() {
                        // Inicializar o mapa de visibilidade
                        ctx.dependencyArrows.forEach(dep => {
                            ctx.visibleArrows.set(dep.id, false);
                        });
                        return ctx; // Para encadeamento de métodos
                    }
                }
            };            

            // Configurar os eventos de seleção e controles
            frappe.aps.highlight.setupSelectionEvent(ctx);
            frappe.aps.controls.setupNavigationButtons(ctx);                        
            frappe.aps.controls.setupDatePicker(ctx);
            frappe.aps.controls.setupZoomControls(ctx);            
            frappe.aps.controls.setupHighlightControls(ctx);
            frappe.aps.controls.addProgressIndicator();  
            frappe.aps.styles.aplicarEstilosAvancados();            
            
            document.querySelector('.timeline-controls').style.display = 'inline';
            const allButtons = document.querySelectorAll('.timeline-controls > button');
            allButtons.forEach(btn => {
                btn.style.marginLeft = '5px';
                btn.style.marginRight = '5px';
            });

            const allZoomButtons = document.querySelectorAll('.zoom-controls > button');
            allZoomButtons.forEach(btn => {
                btn.style.marginLeft = '5px';
                btn.style.marginRight = '5px';
                btn.style.fontSize = 'medium';
            });

            frappe.aps.utils.applyCustomAdjustments(ctx);
            
            // Implementar a renderização progressiva       
            // Configurações para a renderização progressiva
            const batchSize = 10000; // Número de itens por lote
            const batchDelay = 10; // Delay entre lotes em ms
            const totalItems = data.items.length;
            let processedItems = 0;
            
            function renderNextBatch() {
                // Calcular o início e fim do lote atual
                const start = processedItems;
                const end = Math.min(start + batchSize, totalItems);
                const currentBatch = data.items.slice(start, end);
                
                // Adicionar o lote atual ao DataSet
                timelineItems.add(currentBatch);
                
                // Atualizar contador de itens processados
                processedItems = end;
                
                // Atualizar o indicador de progresso
                const progress = Math.floor((processedItems / totalItems) * 100);
                const progressElement = document.getElementById('timeline-progress');
                if (progressElement) {
                    progressElement.textContent = `${progress}%`;
                }
                
                // Verificar se há mais itens para processar
                if (processedItems < totalItems) {
                    // Agendar o próximo lote
                    setTimeout(renderNextBatch, batchDelay);
                } else {
                    // Remover o indicador de progresso quando concluído
                    const indicator = document.getElementById('timeline-loading-indicator');
                    if (indicator) {
                        indicator.style.display = 'none';
                    }
                    
                    // Aplicar ajustes finais
                    setTimeout(() => {
                        frappe.aps.utils.applyCustomAdjustments(ctx);
                        ctx.timeline.redraw();
                        
                        // Notificar usuário que o carregamento foi concluído
                        frappe.show_alert({
                            message: __(`Timeline carregada com sucesso: ${data.groups.length} recursos e ${data.total_de_operacoes} operações`),
                            indicator: 'green'
                        }, 7);
                    }, 100);
                }
            }
            
            // Iniciar a renderização progressiva após um pequeno delay
            // para permitir que a UI seja renderizada primeiro
            setTimeout(renderNextBatch, 50);
            
            // Retornar o contexto para possível uso posterior
            return ctx;
        } catch (error) {
            frappe.aps.utils.showError('Erro ao inicializar o timeline', error.message);
            return null;
        }
    }


    // Função auxiliar para extrair o valor da ordem de um item
    function extractOrderValue(item) {
        // Tentativa 1: Diretamente na propriedade order
        if (item.order) {
            return item.order;
        } 
        // Tentativa 2: Na propriedade data
        else if (item.data && item.data.order) {
            return item.data.order;
        }
        // Tentativa 3: Extrair da ID (assumindo formato 'op-ORDERNAME-OPNAME')
        else if (item.id && item.id.startsWith('op-')) {
            const parts = item.id.split('-');
            if (parts.length >= 2) {
                return parts[1];
            }
        }
        return null;
    }
    

function loadData() {
    try {
        // Obter valores do formulário
        const formValues = frappe.aps.form.getFormValues();
        
        if (!formValues || !formValues.test_aps_name) {
            frappe.aps.utils.showError('Erro ao carregar dados da timeline', 'Não foi informado o campo Teste APS.');
            return;
        }

        frappe.show_alert({
            message: __("Iniciando processamento dos dados..."),
            indicator: 'blue'
        }, 5);
        
        // Remover mensagens de erro anteriores
        $('.timeline-section .error-message').remove();
        
        frappe.call({
            method: "nxerp.nxaps.doctype.teste_aps.teste_aps.process_timeline_data",
            args: {
                "docname": formValues.test_aps_name, //APENAS PARA TESTE
            },
            // freeze: true,
            // freeze_message: __("Processando e carregando os dados da timeline..."),
            callback: function(r) {
                if (r.message && r.message.success) {
                    // Inicializar a timeline com os dados
                    frappe.aps.timelineContext = initializeTimeline(r.message.data);
                    
                    // Atualiza no Form os campos de parâmetros do APS
                    if (r.message.data?.aps_manufacturing_center && r.message.data?.aps_scheduling_method && r.message.data?.aps_scheduling_priority_rules) {
                        frappe.aps.form.updateApsParameters(r.message.data.aps_manufacturing_center, r.message.data.aps_scheduling_method, r.message.data.aps_scheduling_priority_rules);
                    }
                    
                    // Carregar dados no grid
                    if (frappe.aps.grid && r.message.data?.operacoes_alocadas) {
                        frappe.aps.grid.updateGrid(r.message.data.operacoes_alocadas);
                    }

                } else {
                    const error_msg = r.message && r.message.error ? r.message.error : "Erro desconhecido";
                    frappe.aps.utils.showError('Erro ao processar dados da timeline', error_msg);
                    
                    // Adicionar uma mensagem de erro visível na seção da timeline
                    _timelineSection.append(
                        `<div class="error-message alert alert-danger">
                            <i class="fa fa-exclamation-triangle"></i> 
                            ${__('Ocorreu um erro ao carregar os dados. Por favor, tente novamente.')}
                        </div>`
                    );
                }
            },
            error: function(xhr, textStatus) {
                frappe.aps.utils.showError(
                    'Erro de Comunicação', 
                    'Não foi possível se comunicar com o servidor. Verifique sua conexão.'
                );
                
                // Adicionar uma mensagem de erro visível na seção da timeline
                _timelineSection.append(
                    `<div class="error-message alert alert-danger">
                        <i class="fa fa-exclamation-triangle"></i> 
                        ${__('Erro de comunicação com o servidor. Verifique sua conexão e tente novamente.')}
                    </div>`
                );
            }
        });            

    } catch (e) {
        frappe.aps.utils.showError('Erro no carregamento da timeline', e.message);
    }
}
    
    return {
        init: init,
        initializeTimeline: initializeTimeline,
        loadData: loadData
    };
})();

