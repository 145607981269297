frappe.provide('frappe.aps.styles');

frappe.aps.styles = (function() {
    function aplicarEstilosAvancados() {
        const existingStyle = document.getElementById('timeline-advanced-styles');
        if (existingStyle) {
            existingStyle.remove();
        }
        
        const style = document.createElement('style');
        style.id = 'timeline-advanced-styles';
        style.textContent = `
            /* Estilos para o cabeçalho do mês/ano */
            .vis-panel.vis-top .vis-text.vis-major {
                text-align: center;
                font-weight: 600;
                border-right: 1px solid #ccc;
                background-color: #f0f0f0;
            }
            
            /* Estilos para os dias da semana */
            .vis-panel.vis-top .vis-text.vis-minor {
                text-align: center;
                font-weight: 500;
                border-right: 1px solid #e0e0e0;
                border-left: 1px solid #e0e0e0;
                border-top: 1px solid #e0e0e0;
                padding: 3px 0;            
            }
            
            /* Fundo alternado para dias pares/ímpares */
            .vis-panel.vis-top .vis-text.vis-even {
                background-color: white;
            }
            
            .vis-panel.vis-top .vis-text.vis-odd {
                background-color: #f5f5f5;
            }
            
            /* Destacar dia atual */
            .vis-panel.vis-top .vis-text.vis-today {
                font-weight: bold;
                color:rgb(0, 0, 0);
                background-color:rgba(31, 167, 31, 0.38);
            }
            
            /* Estilizar dias do mês */
            .vis-panel.vis-top .vis-text.vis-day1,
            .vis-panel.vis-top .vis-text.vis-day15 {
                border-left: 2px solid #aaa; /* Marca visual para início/meio do mês */
            }
            
            /* Grade vertical mais forte para os dias principais */
            .vis-panel.vis-center .vis-grid.vis-vertical.vis-major {
                border-left: 2px solid #ccc;
            }
            
            /* Grade vertical mais suave para subdivisões */
            .vis-panel.vis-center .vis-grid.vis-vertical.vis-minor {
                border-left: 1px dashed #ddd;
            }
            
            /* Linha de separação do cabeçalho */
            .vis-panel.vis-top {
                border-bottom: 3px solid #999 !important;
            }
            
            /* Espaçamento entre dias e meses */
            .vis-time-axis .vis-text {
                padding: 3px 0;
                text-align: center;
            }
            
            /* Diferenciar visualmente os diferentes meses */
            .vis-panel.vis-top .vis-text.vis-january,
            .vis-panel.vis-top .vis-text.vis-march,
            .vis-panel.vis-top .vis-text.vis-may,
            .vis-panel.vis-top .vis-text.vis-july,
            .vis-panel.vis-top .vis-text.vis-september,
            .vis-panel.vis-top .vis-text.vis-november {
                background-color: #f0f4f9;
                width: 100%;
            }
            
            .vis-panel.vis-top .vis-text.vis-february,
            .vis-panel.vis-top .vis-text.vis-april,
            .vis-panel.vis-top .vis-text.vis-june,
            .vis-panel.vis-top .vis-text.vis-august,
            .vis-panel.vis-top .vis-text.vis-october,
            .vis-panel.vis-top .vis-text.vis-december {
                background-color: #f9f4f0;
            }
        `;
        document.head.appendChild(style);
    }
    
    return {
        aplicarEstilosAvancados: aplicarEstilosAvancados
    };
})();