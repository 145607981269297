frappe.provide('frappe.aps.grid');

frappe.aps.grid = (function() {
    let _container = null;
    let _gridData = [];
    let _gridDataRaw = []; // Dados brutos para exportação
    let _datatable = null;
    let _viewMode = 'hierarchy'; // 'hierarchy' ou 'flat'
    let _originalData = []; // Para armazenar os dados originais
    let _flatViewData = []; // Para armazenar apenas operações (indent=1)
    
    function init(page) {
        setupContainer(page);
        applyGridStyles()
    }

    function setupContainer(page) {
        // Remover qualquer seção de grid existente
        $('.grid-section').remove();
        
        // Adicionar seção para o grid
        const gridSection = $('<div class="grid-section"></div>').appendTo(page.main);
        
        // HTML com estilos inline e estrutura melhorada
        const gridHtml = `   
          <div class="grid-controls">
              <h5><i class="fa fa-table"></i> Operações Alocadas</h5>
              <div class="btn-group">
                <button id="btn-grid-refresh" class="btn btn-sm btn-default" title="Atualizar dados">
                  <i class="fa fa-refresh"></i> Atualizar
                </button>
                <button id="btn-expand-all" class="btn btn-sm btn-default" title="Expandir todas as ordens">
                  <i class="fa fa-plus-square"></i> Expandir Tudo
                </button>
                <button id="btn-collapse-all" class="btn btn-sm btn-default" title="Colapsar todas as ordens">
                  <i class="fa fa-minus-square"></i> Colapsar Tudo
                </button>
                <!-- O botão de alternar visualização será adicionado dinamicamente -->
              </div>
          </div>
          
          <div id="operations_grid" class="grid-container"></div>
          <div class="text-muted text-right mt-2" style="font-size: 0.8em;">
            * Clique em uma operação para destacá-la no timeline
          </div>
        `;
        
        gridSection.html(gridHtml);
        _container = document.getElementById('operations_grid');
        
        // Configurar eventos dos botões
        setupGridControls();
    }

    function applyGridStyles() {
        const style = document.createElement('style');
        style.textContent = `
            /* Container do grid */
            .grid-container {
                width: 98%;
                height: 220px !important; /* Altura reduzida para 220px */
                border: 1px solid #e0e0e0;
                background-color: #fff;
                overflow: auto;
                margin-left: 1%;
            }
            
            /* Estilos para o DataTable */
            .datatable {
                width: 100%;
                height: 100%;
            }
            
            /* Área de rolagem */
            .datatable .dt-scrollable {
                overflow: auto;
                height: 200px !important; /* Ajustar para combinar com a altura do container */
                max-height: none !important;
            }
            
            /* Ajustes para melhorar visualização */
            .datatable .dt-cell {                
                border-right: 1px solid #eee;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px; /* Letra menor */
            }
            
            /* Linhas com altura fixa */
            .datatable .dt-row {
                height: 30px !important; /* Altura fixa de 30px */
            }
            
            /* Cabeçalho */
            .datatable .dt-header {
                background-color: #f5f7fa;
                border-bottom: 1px solid #d1d8dd;
                font-weight: bold;
                font-size: 12px; /* Letra menor no cabeçalho também */
            }
            
            /* Linhas alternadas */
            .datatable .dt-row:nth-child(even) {
                background-color: #f9f9f9;
            }
            
            /* Hover nas linhas */
            .datatable .dt-row:hover {
                background-color: #f0f4f9;
                cursor: pointer;
            }
            
            /* Estilos específicos para TreeView */
            .datatable .dt-tree-node {
                cursor: pointer;
            }
            
            /* Estilo para linhas de ordem (pai) */
            .datatable .dt-row[data-indent="0"] {
                font-weight: bold;
                background-color: #e1f5fe; /* Azul claro para ordens */
            }
            
            /* Estilo para linhas de operação (filhos) */
            .datatable .dt-row[data-indent="1"] {
                font-weight: normal;
            }
        `;
        document.head.appendChild(style);
    }
    
    function setupGridControls() {
        // Configurar botão de atualização
        $('#btn-grid-refresh').off('click').on('click', function() {
            refreshGrid();
        });
        
        // Configurar botões de expandir/colapsar
        $('#btn-expand-all').off('click').on('click', function() {
            expandAllNodes();
        });
        
        $('#btn-collapse-all').off('click').on('click', function() {
            collapseAllNodes();
        });
        
        // Configurar o botão de alternância de visualização
        setupViewModeButton();
    }
    
    function setupViewModeButton() {
        // Criar o botão de alternância de visualização
        const toggleViewButton = document.createElement('button');
        toggleViewButton.id = 'btn-toggle-view';
        toggleViewButton.className = 'btn btn-sm btn-default';
        toggleViewButton.title = 'Alternar entre visualização hierárquica e plana';
        toggleViewButton.innerHTML = '<i class="fa fa-th-list"></i> Mostrar Apenas Operações';
        
        // Adicionar ao grupo de botões
        const controlsDiv = document.querySelector('.grid-controls .btn-group');
        if (controlsDiv) {
            controlsDiv.appendChild(toggleViewButton);
            
            // Configurar o evento de clique
            $('#btn-toggle-view').off('click').on('click', function() {
                toggleViewMode();
            });
        }
    }
    
    function toggleViewMode() {
        const button = document.getElementById('btn-toggle-view');
        
        if (_viewMode === 'hierarchy') {
            // Mudar para visualização plana (apenas operações)
            _viewMode = 'flat';
            if (button) {
                button.innerHTML = '<i class="fa fa-sitemap"></i> Mostrar Hierarquia';
                button.title = 'Mostrar visualização hierárquica com ordens';
            }
            
            // Mostrar/esconder botões de expandir/colapsar
            $('#btn-expand-all, #btn-collapse-all').hide();
            
            // Filtrar apenas operações
            _flatViewData = _originalData.filter(item => item.indent === 1);
            
            // Atualizar o grid para mostrar apenas operações
            _datatable.refresh(_flatViewData);
            
        } else {
            // Mudar para visualização hierárquica (com ordens)
            _viewMode = 'hierarchy';
            if (button) {
                button.innerHTML = '<i class="fa fa-th-list"></i> Mostrar Apenas Operações';
                button.title = 'Mostrar apenas as operações sem agrupar';
            }
            
            // Mostrar botões de expandir/colapsar
            $('#btn-expand-all, #btn-collapse-all').show();
            
            // Restaurar visualização hierárquica
            _datatable.refresh(_originalData);
            
            // Colapsar todos os nós
            setTimeout(() => {
                collapseAllNodes();
            }, 100);
        }
        
        // Mostrar mensagem informativa
        frappe.show_alert({
            message: __(`Visualização alterada para modo ${_viewMode === 'hierarchy' ? 'hierárquico' : 'plano'}`),
            indicator: 'blue'
        }, 2);
    }
    
    function expandAllNodes() {
        if (_viewMode !== 'hierarchy') {
            return; // Não faz sentido em modo plano
        }
        
        if (_datatable && _datatable.rowmanager && _datatable.rowmanager.expandAllNodes) {
            _datatable.rowmanager.expandAllNodes();
        } else {
            // Implementação manual
            const rows = _datatable.datamanager.getRows();
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (row && row.meta && !row.meta.isLeaf) {
                    _datatable.rowmanager.expandNode(i);
                }
            }
        }
        
        frappe.show_alert({
            message: __('Todas as ordens expandidas'),
            indicator: 'blue'
        }, 2);
    }
    
    function collapseAllNodes() {
        if (_viewMode !== 'hierarchy') {
            return; // Não faz sentido em modo plano
        }
        
        if (_datatable && _datatable.rowmanager && _datatable.rowmanager.collapseAllNodes) {
            _datatable.rowmanager.collapseAllNodes();
        } else {
            // Implementação manual
            const rows = _datatable.datamanager.getRows();
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (row && row.meta && !row.meta.isLeaf) {
                    _datatable.rowmanager.collapseNode(i);
                }
            }
        }
        
        frappe.show_alert({
            message: __('Todas as ordens colapsadas'),
            indicator: 'blue'
        }, 2);
    }
    
    function refreshGrid() {
        // Mostrar indicador de carregamento no botão
        const refreshButton = document.getElementById('btn-grid-refresh');
        if (refreshButton) {
            const originalContent = refreshButton.innerHTML;
            refreshButton.innerHTML = '<i class="fa fa-spinner fa-spin"></i> Atualizando...';
            refreshButton.disabled = true;
        }
        
        // Verificar disponibilidade de dados
        if (frappe.aps.timelineContext && frappe.aps.timelineContext.data && 
            frappe.aps.timelineContext.data.operacoes_alocadas) {
            
            // Usar setTimeout para dar feedback visual antes de processar muitos dados
            setTimeout(() => {
                updateGrid(frappe.aps.timelineContext.data.operacoes_alocadas);
                
                // Restaurar estado original do botão
                if (refreshButton) {
                    refreshButton.innerHTML = '<i class="fa fa-refresh"></i> Atualizar';
                    refreshButton.disabled = false;
                }
                
                // Mostrar informação sobre a atualização
                const operacoesCount = frappe.aps.timelineContext.data.operacoes_alocadas.length;
                frappe.show_alert({
                    message: __(`Grid atualizado com sucesso. ${operacoesCount.toLocaleString()} operações carregadas.`),
                    indicator: 'green'
                }, 3);
            }, 100);
        } else {
            // Caso não existam dados
            if (refreshButton) {
                refreshButton.innerHTML = '<i class="fa fa-refresh"></i> Atualizar';
                refreshButton.disabled = false;
            }
            
            frappe.show_alert({
                message: __("Nenhum dado disponível para atualizar o grid"),
                indicator: 'orange'
            }, 3);
        }
    }
    
    function initializeGrid(data) {
        // Limpar mensagens anteriores
        if (_container) {
            _container.innerHTML = '';
        }
        
        // Adicionar indicador de carregamento
        if (_container) {
            _container.innerHTML = '<div class="loading-indicator text-center p-4"><i class="fa fa-spinner fa-spin"></i> Carregando dados...</div>';
        }
        
        // Se já existe um datatable, destrua-o primeiro
        if (_datatable) {
            try {
                _datatable.destroy();
            } catch (e) {
                console.warn("Erro ao destruir datatable:", e);
            }
            _datatable = null;
        }
        
        if (!_container) {
            console.error("Container do grid não encontrado");
            return;
        }
        
        // Definir colunas do grid com larguras ajustadas
        const columns = [
            {
                name: 'Ordem',
                id: 'ordem',
                width: '10%',
                sortable: true
            },
            {
                name: 'Código',
                id: 'codigo',
                width: '10%',
                editable: false,
                sortable: true
            },
            {
                name: 'Nome',
                id: 'nome',
                width: '15%',
                editable: false,
                resizable: false,
                sortable: true
            },
            {
                name: 'Início',
                id: 'inicio',
                width: '15%',
                editable: false,
                resizable: false,
                sortable: true,
                format: (value) => formatDateTime(value)
            },
            {
                name: 'Fim',
                id: 'fim',
                width: '15%',
                editable: false,
                resizable: false,
                sortable: true,
                format: (value) => formatDateTime(value)
            },
            {
                name: 'Recurso',
                id: 'recurso',
                width: '15%',
                editable: false,
                resizable: false,
                sortable: true
            },
            {
                name: 'Duração',
                id: 'duracao',
                width: '10%',
                editable: false,
                resizable: false,
                sortable: true,
                format: (value) => `${value} h`
            }
        ];
        
        // Limpar container antes de criar o novo datatable
        _container.innerHTML = '';
        
        // Verificar se há dados
        if (!data || !Array.isArray(data) || data.length === 0) {
            _container.innerHTML = '<div class="text-center p-4 text-muted">Nenhuma operação alocada encontrada</div>';
            return null;
        }
        
        // Mostrar indicador da quantidade total
        const totalCount = data.length;
        const countIndicator = document.createElement('div');
        countIndicator.className = 'text-muted text-right mb-2';
        countIndicator.style.fontSize = '0.85em';
        countIndicator.innerHTML = `Total: ${totalCount.toLocaleString()} operações`;
        _container.parentNode.insertBefore(countIndicator, _container);
        
        // Preparar dados para o grid - usar todos os registros
        const processedData = processGridData(data);
        
        // Guardar referência aos dados para exportação
        _gridData = processedData;
        
        // Armazenar os dados originais para alternância de visualização
        _originalData = [...processedData];
        
        // Preparar dados filtrados para modo plano
        _flatViewData = processedData.filter(item => item.indent === 1);
        
        // Atribuir um ID ao container para usar com seletor
        _container.id = 'operations-grid-container';
        
        // Usar setTimeout com um tempo menor
        setTimeout(() => {
            try {
                // Criar o DataTable com configurações para TreeView
                try {
                    _datatable = new DataTable('#operations-grid-container', {
                        layout: 'ratio',
                        columns: columns,
                        serialNoColumn: false,
                        treeView: true,
                        inlineFilters: true,
                        noDataMessage: 'Nenhuma operação alocada encontrada',
                        data: processedData,
                        cellHeight: 30, // Definir altura da célula explicitamente
                        dynamicRowHeight: false // Desabilitar altura dinâmica
                    });
                } catch (e) {
                    console.warn("Erro com DataTable, tentando frappe.DataTable:", e);
                    _datatable = new frappe.DataTable('#operations-grid-container', {
                        layout: 'ratio',
                        columns: columns,
                        serialNoColumn: false,
                        treeView: true,
                        noDataMessage: 'Nenhuma operação alocada encontrada',
                        data: processedData,
                        cellHeight: 30, // Definir altura da célula explicitamente
                        dynamicRowHeight: false // Desabilitar altura dinâmica
                    });
                }
                
                // Adicionar evento de clique às linhas manualmente
                _container.addEventListener('click', function(event) {
                    const rowElement = event.target.closest('.dt-row');
                    if (rowElement) {
                        const indent = rowElement.getAttribute('data-indent');
                        const rowIndex = parseInt(rowElement.dataset.rowIndex);
                        
                        // Escolher o conjunto de dados correto
                        const currentData = _viewMode === 'flat' ? _flatViewData : _gridData;
                        
                        // Se for operação (indent=1), destacar no timeline
                        if (indent === "1" && rowIndex !== undefined && rowIndex >= 0 && rowIndex < currentData.length) {
                            highlightTimelineItem(currentData[rowIndex].id);
                        }
                    }
                });
                
                // Adicionar informação sobre o recurso de filtro
                const filterTip = document.createElement('div');
                filterTip.className = 'text-muted mt-2';
                filterTip.style.fontSize = '0.85em';
                filterTip.innerHTML = 'Dica: Clique com o botão direito em qualquer coluna para filtrar';
                _container.parentNode.appendChild(filterTip);
                
                // Iniciar com todos os nós colapsados
                setTimeout(() => {
                    collapseAllNodes();
                }, 100);
                
            } catch (e) {
                console.error("Erro ao criar datatable:", e);
                _container.innerHTML = '<div class="alert alert-danger">Erro ao carregar grid. Verifique o console para mais detalhes.</div>';
            }
        }, 50);
        
        return _datatable;
    }
    
    function processGridData(data) {
        if (!data || !Array.isArray(data)) {
            return [];
        }
        
        console.log(`Processando ${data.length} registros para o grid`);
        
        // Simplesmente processar todos os dados sem limitação
        return data.map(item => {
            // Calcular duração
            let duracao = 0;
            if (item.inicio && item.fim) {
                const start = new Date(item.inicio);
                const end = new Date(item.fim);
                duracao = frappe.aps.utils.calculateDuration(start, end);
            }
            
            return {
                id: item.id || '',
                codigo: item.codigo || '',
                nome: item.nome || '',
                inicio: item.inicio || '',
                fim: item.fim || '',
                recurso: item.recurso || '',
                ordem: item.ordem || '',
                indent: parseInt(item.indent) || 0,
                isLeaf: parseInt(item.indent) === 1,
                duracao: duracao.toFixed(1)
            };
        });
    }
    
    function formatDateTime(dateStr) {
        if (!dateStr) return '';
        try {
            const date = new Date(dateStr);
            return date.toLocaleDateString() + ' ' + 
                   date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        } catch (e) {
            return dateStr;
        }
    }
    
    function highlightTimelineItem(id) {
        if (!id || !id.startsWith('op-') || !frappe.aps.timelineContext || !frappe.aps.timelineContext.timeline) {
            return;
        }
        
        try {
            // Selecionar o item no timeline
            frappe.aps.timelineContext.timeline.setSelection(id, {
                focus: true
            });
            
            // Rolar para o item com efeito de piscar
            frappe.aps.timelineContext.timeline.focus(id);
            // forcar o scroll para o item
            frappe.aps.timelineContext.timeline.scrollToItem(id);
            
            // Encontrar o elemento no DOM
            setTimeout(() => {
                try {
                    // Adicionar efeito de destaque visual para o item
                    const element = document.querySelector(`.vis-item[data-id='${id}']`);
                    if (element) {
                        // adicionar border: 2px solid #000 no item
                        element.style.border = '2px solid #000';                                            
                    }                    
                    
                    // // Mostrar as dependências se necessário
                    // if (frappe.aps.highlight && frappe.aps.highlight.handleSelectedItem) {
                    //     frappe.aps.highlight.handleSelectedItem(frappe.aps.timelineContext);
                    // }
                } catch (e) {
                    console.warn("Erro ao destacar visualmente:", e);
                }
            }, 300);
            
            // Mostrar mensagem informativa
            frappe.show_alert({
                message: __("Operação destacada na timeline"),
                indicator: 'blue'
            }, 2);
            
        } catch (error) {
            console.error("Erro ao destacar item na timeline:", error);
        }
    }    
    
    function updateGrid(data) {
        // Armazenar variável global para os dados brutos
        _gridDataRaw = data;
        
        // Se o grid ainda não foi inicializado, inicialize-o
        if (!_datatable) {
            initializeGrid(data);
        } else {
            try {
                // Processar novos dados
                const processedData = processGridData(data);
                
                // Atualizar referências de dados
                _gridData = processedData;
                _originalData = [...processedData];
                
                // Atualizar também os dados filtrados para o modo plano
                _flatViewData = processedData.filter(item => item.indent === 1);
                
                // Atualizar com base no modo de visualização atual
                if (_viewMode === 'flat') {
                    // Em modo plano, mostrar apenas operações
                    _datatable.refresh(_flatViewData);
                } else {
                    // Em modo hierárquico, mostrar todos os dados
                    _datatable.refresh(_originalData);
                    
                    // Iniciar com todos colapsados
                    setTimeout(() => {
                        collapseAllNodes();
                    }, 100);
                }
                
                // Atualizar contador de registros
                const countIndicator = _container.parentNode.querySelector('.text-muted.text-right.mb-2');
                if (countIndicator) {
                    countIndicator.innerHTML = `Total: ${data.length.toLocaleString()} operações`;
                } else {
                    // Se o contador não existir, adicionar
                    const newCountIndicator = document.createElement('div');
                    newCountIndicator.className = 'text-muted text-right mb-2';
                    newCountIndicator.style.fontSize = '0.85em';
                    newCountIndicator.innerHTML = `Total: ${data.length.toLocaleString()} operações`;
                    _container.parentNode.insertBefore(newCountIndicator, _container);
                }
            } catch (error) {
                console.error("Erro ao atualizar grid:", error);
                frappe.show_alert({
                    message: __("Erro ao atualizar grid: ") + error.message,
                    indicator: 'red'
                }, 5);
            }
        }
    }    
    
    // Adicionar função para limpar recursos e evitar vazamentos de memória
    function cleanup() {
        try {
            if (_datatable) {
                _datatable.destroy();
                _datatable = null;
            }
            
            // Limpar dados para liberar memória
            _gridData = [];
            _gridDataRaw = [];
            _originalData = [];
            _flatViewData = [];
            
            if (_container) {
                _container.innerHTML = '';
            }
            
            // Remover eventos
            $('#btn-grid-refresh').off('click');
            $('#btn-expand-all').off('click');
            $('#btn-collapse-all').off('click');
            $('#btn-toggle-view').off('click');
            
            console.log("Grid de operações limpo com sucesso");
        } catch (e) {
            console.error("Erro ao limpar grid:", e);
        }
    }
    
    return {
        init: init,
        initializeGrid: initializeGrid,
        updateGrid: updateGrid,
        refreshGrid: refreshGrid,
        cleanup: cleanup
    };
})();