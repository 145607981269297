frappe.provide('frappe.aps.form');

frappe.aps.form = (function() {
    let _formControls = {};
    
    function init(page) {
        setupFormSection(page);
    }
    
    function setupFormSection(page) {
        let filterSection = $('<div class="filter-section"></div>').appendTo(page.main);
        
        let formHtml = `
            <div class="form-section">
                <div class="row">
                    <div class="form-column col-md-3">
                        <div class="form-group" id="test_aps_name_field"></div>
                    </div>    
                    <div class="form-column col-md-3">
                        <div class="form-group" id="aps_manufacturing_center_field"></div>
                    </div>
                    <div class="form-column col-md-3">
                        <div class="form-group" id="aps_scheduling_method_field"></div>
                    </div>
                    <div class="form-column col-md-3">
                        <div class="form-group" id="aps_scheduling_priority_rules_field"></div>
                    </div>
                </div>
            </div>
        `;
        
        filterSection.html(formHtml);
        
        setTimeout(createFormFields, 100);
    }
    
    function createFormFields() {
        _formControls.test_aps_name = frappe.ui.form.make_control({
            parent: document.getElementById('test_aps_name_field'),
            df: {
                fieldtype: 'Link',
                options: 'Teste APS',
                fieldname: 'test_aps_name',
                label: 'Teste APS',
                reqd: 1,
            },
            render_input: true
        });
        
        _formControls.scheduling_method = frappe.ui.form.make_control({
            parent: document.getElementById('aps_scheduling_method_field'),
            df: {
                fieldtype: 'Link',
                options: 'APS Scheduling Method',
                fieldname: 'aps_scheduling_method',
                label: 'Método de Sequenciamento',
                reqd: 1,
            },
            render_input: true
        });
        
        _formControls.manufacturing_center = frappe.ui.form.make_control({
            parent: document.getElementById('aps_manufacturing_center_field'),
            df: {
                fieldtype: 'Link',
                options: 'APS Manufacturing Center',
                fieldname: 'aps_manufacturing_center',
                label: 'Centro de Manufatura',
                reqd: 1,
            },
            render_input: true
        });
        
        _formControls.priority_rules = frappe.ui.form.make_control({
            parent: document.getElementById('aps_scheduling_priority_rules_field'),
            df: {
                fieldtype: 'Link',
                options: 'APS Scheduling Priority Rules',
                fieldname: 'aps_scheduling_priority_rules',
                label: 'Regras de Priorização',
                reqd: 1,
            },
            render_input: true
        });
        
        console.log("Campos de formulário criados");
    }
    
    function validateForm() {
        try {
            const allValid = Object.values(_formControls).every(control => {
                const value = control.get_value();
                return value && value.trim() !== '';
            });
            
            if (frappe.aps.page) {
                if (allValid) {
                    frappe.aps.page.primary_action.prop('disabled', false);
                } else {
                    frappe.aps.page.primary_action.prop('disabled', true);
                }
            }
            
            return allValid;
        } catch (e) {
            console.error("Erro ao validar formulário:", e);
            return false;
        }
    }
    
    function getFormValues() {
        try {
            return {
                test_aps_name: _formControls.test_aps_name.get_value(),
                scheduling_method: _formControls.scheduling_method.get_value(),
                manufacturing_center: _formControls.manufacturing_center.get_value(),
                priority_rules: _formControls.priority_rules.get_value()
            };
        } catch (e) {
            console.error("Erro ao obter valores do formulário:", e);
            frappe.aps.utils.showError('Erro no Formulário', 'Não foi possível obter os valores dos campos.');
            return {};
        }
    }
    
    function executarSequenciamento() {
        return new Promise((resolve, reject) => {            
            const formValues = getFormValues();
            
            if(!formValues.scheduling_method || !formValues.manufacturing_center || !formValues.priority_rules || !formValues.test_aps_name) {
                reject(new Error("Preencha todos os campos do formulário"));
                return;
            }

            frappe.show_alert({
                message: __("Executando o método de sequenciamento..."),
                indicator: 'blue'
            }, 5);

            frappe.call({            
                method: "nxerp.nxaps.doctype.teste_aps.teste_aps.executa_metodo_de_sequenciamento",
                args: {
                    "docname": formValues.test_aps_name, //APENAS PARA TESTE
                    "id_metodo": formValues.scheduling_method,
                    "id_centro": formValues.manufacturing_center,
                    "id_regra_de_priorizacao": formValues.priority_rules,
                },
                // freeze: true,
                // freeze_message: __("Executando o método de sequenciamento..."),
                callback: function(r) {                    
                    if (r.message) {                        
                        const jobId = r.message.job_id || '';
                        frappe.show_alert({
                            message: __(`Sequenciamento iniciado com sucesso. Job ID: ${jobId}`),
                            indicator: 'green'
                        }, 5);
                        resolve(r.message);                        
                    }
                    else {
                        frappe.show_alert({
                            message: __("Erro ao iniciar o sequenciamento"),
                            indicator: 'red'
                        }, 5);
                        reject(new Error("Erro ao iniciar o sequenciamento"));
                    }
                },
                error: function(xhr, textStatus) {
                    reject(new Error(`Erro de comunicação: ${textStatus || 'Não foi possível se comunicar com o servidor'}`));
                }
            });
        });
    }
    
    function updateApsParameters(aps_manufacturing_center, aps_scheduling_method, aps_scheduling_priority_rules) {

        // Métodos que atualiza os campos do formulário
        _formControls.manufacturing_center.set_value(aps_manufacturing_center);
        _formControls.scheduling_method.set_value(aps_scheduling_method);
        _formControls.priority_rules.set_value(aps_scheduling_priority_rules);

    }

    return {
        init: init,
        getFormValues: getFormValues,
        executarSequenciamento: executarSequenciamento,
        updateApsParameters: updateApsParameters,
        validateForm: validateForm
    };
})();