import cytoscape from "cytoscape";
import klay from "cytoscape-klay"; 

cytoscape.use(klay);

const new_cytoscape = (container, data) => {    
    let width = container.clientWidth;
    let height = container.clientHeight;
    
    // Função para determinar a posição dos nós dinamicamente com base no nível
    const calculatePosition = (level, index, total) => {
        const x = (level + 1) * (width / (Math.max(...data.map(item => item.level)) + 2));
        const y = (index + 1) * (height / (total + 1));
        return { x, y };
    };

    // Criar elementos dinamicamente com base nos dados recebidos
    const elements = data.map((item, index) => {
        const position = calculatePosition(item.level, index, data.length);
        let color = '#808080';
        if(item.color != undefined && item.color != ''){
            color = item.color.startsWith('#') ? item.color : `#${item.color}`;
        }
        const parentLabel = item.parent ? `Origem: ${item.parent.slice(0, 1).join('/ ')}${item.parent.length > 1 ? '/ ...' : ''}` : '';
        return {
            data: { id: item.docname, label: `${item.doctype} ${item.docname}\n${parentLabel}`, type: item.doctype.toLowerCase().replace(/ /g, '-'), color: color, origin: item.origin },
            position: position
        };
    });

    // Adicionar arestas (links) entre os nós com base no campo 'parent'
    data.forEach((item, index) => {
        if (item.parent && item.parent.length > 0) {
            item.parent.forEach((parentDocname, parentIndex) => {
                // Verifica se o parentDocname existe no array data
                if (data.some(d => d.docname === parentDocname)) {
                    elements.push({
                        data: { id: `e${index}-${parentIndex}`, source: parentDocname, target: item.docname }
                    });
                }
            });
        }
    });
    
    const cy = cytoscape({
        container: container,
        elements: elements,
        style: [
            {
                selector: 'node',
                style: {
                    'shape': 'round-rectangle', // Usa a forma nativa arredondada
                    'label': 'data(label)',
                    'text-wrap': 'wrap',       // ativa a quebra de linha
                    'text-max-width': '100px', // largura máxima para o texto antes de quebrar
                    'text-valign': 'center',
                    'text-halign': 'center',
                    'font-size': '12px',
                    'font-weight': '800',
                    'width': '140px',
                    'height': '80px',
                    'color': '#fff',                    
                    'background-color': 'data(color)',
                }
            },            
            {
                selector: 'node[origin = 1]', // Aplica apenas ao nó amarelo
                style: {
                    'background-color': '#FFFF00',                  
                    'color': '#000000',                                        
                }
                
            },
            {
                selector: 'edge',
                style: {
                    'width': 3,
                    'line-color': '#ccc',
                    'target-arrow-color': '#ccc',
                    'target-arrow-shape': 'triangle',
                    'curve-style': 'bezier'
                }
            }
        ],
        layout: {
          name: 'klay',
          nodeDimensionsIncludeLabels: true,
          fit: true, // Ajusta o layout para caber na viewport
          padding: 20,
          animate: false,
          klay: {
              direction: 'RIGHT', // Direção do layout (LEFT, RIGHT, DOWN, UP)
              spacing: 20,
              edgeRouting: 'SPLINES',
              nodePlacement: 'BRANDES_KOEPF',
              edgeSpacingFactor: 2,
              fixedAlignment: 'BALANCED',
              inLayerSpacingFactor: 1
          }
      },     
      minZoom: 0.1, 
      maxZoom: 6
    });

    const resizeGraph = () => {
        width = container.clientWidth;
        height = container.clientHeight;
        cy.resize();
        cy.fit();
    };

    // Adiciona o evento de redimensionamento
    window.addEventListener('resize', resizeGraph); 

    const addBlurEffect = (cy) => {
        cy.nodes('[origin = 1]').forEach(node => {
            const nodeId = node.id();
            const originalColor = node.style('background-color');
    
            for (let i = 1; i <= 15; i++) {
                const sizeIncrease = i * 1.2;  // Agora cresce mais devagar para ficar mais denso
                const opacity = Math.max(0.05, 0.4 - i * 0.02); // Mais opacidade para manter um brilho definido
        
                cy.add({
                    group: 'nodes',
                    data: { id: `glow-${i}-${nodeId}` },
                    position: node.position(),
                    selectable: false,
                    grabbable: false,
                    classes: 'glow-node'
                });
        
                cy.$(`#glow-${i}-${nodeId}`).style({
                    'width': `${150 + sizeIncrease}px`,  
                    'height': `${90 + sizeIncrease}px`,
                    'background-color': originalColor,
                    'opacity': opacity,  
                    'border-width': '0px',
                    'z-index': -i // Mantém as camadas no fundo
                });
            }
        });
    };
        
    addBlurEffect(cy);

    cy.on('click', 'node', function(evt) {
        const node = evt.target;
        let doctype = node.data('type');
        const docname = node.data('id');
        const baseUrl = window.location.origin;                 
        
        const url = `${baseUrl}/app/${doctype}/${docname}`;
        window.open(url, '_blank');
    });
};

frappe.nxerp.cytoscape = new_cytoscape;