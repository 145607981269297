frappe.provide('frappe.aps.utils');

frappe.aps.utils = (function() {
    function showError(title, message, logToConsole = true) {
        frappe.msgprint({
            title: __(title),
            message: __(message),
            indicator: 'red'
        });
        
        if (logToConsole) {
            console.error(`${title}: ${message}`);
        }
    }
    
    function applyCustomAdjustments(ctx) {
        if (!ctx || !ctx.container) return;
        
        const labelSet = ctx.container.querySelector('.vis-labelset');
        if (labelSet) {
            labelSet.style.width = '240px';
            labelSet.style.minWidth = '240px';
            // Adicionar atributo title a todos os rótulos
            labelSet.querySelectorAll('.vis-label').forEach(label => {
                const texto = label.textContent.trim();
                label.setAttribute('title', texto);
                
                // Garantir que os textos não quebrem
                const innerLabel = label.querySelector('.vis-inner');
                if (innerLabel) {
                    innerLabel.style.whiteSpace = 'nowrap';
                    innerLabel.style.overflow = 'hidden';
                    innerLabel.style.textOverflow = 'ellipsis';
                    innerLabel.style.width = '100%';
                    innerLabel.style.fontSize = 'smaller';
                }
            });            
        }
    
        const topPanel = ctx.container.querySelector('.vis-panel.vis-top');
        if (topPanel) {
            topPanel.style.backgroundColor = 'transparent';
            topPanel.style.border = 'none';
        }
    
        const bottomPanel = ctx.container.querySelector('.vis-panel.vis-bottom');
        if (bottomPanel) {
            bottomPanel.style.height = '0px';
            bottomPanel.style.minHeight = '0px';
            bottomPanel.style.border = 'none';
            bottomPanel.style.backgroundColor = 'transparent';
        }
    
        const timeAxis = ctx.container.querySelector('.vis-time-axis');
        if (timeAxis) {
            timeAxis.style.borderBottom = '1px solid #ddd';
        }
    }
    
    function formatDate(date, format = 'medium') {
        if (!date) return '';
        
        try {
            switch (format) {
                case 'short':
                    return frappe.datetime.str_to_user(frappe.datetime.get_datetime_as_string(date));
                case 'medium':
                    return frappe.datetime.global_date_format(date);
                case 'long':
                    return date.toLocaleDateString(frappe.boot.lang, {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    });
                default:
                    return frappe.datetime.str_to_user(frappe.datetime.get_datetime_as_string(date));
            }
        } catch (e) {
            console.error("Erro ao formatar data:", e);
            return date.toString();
        }
    }
    
    function calculateDuration(startDate, endDate, unit = 'hours') {
        if (!startDate || !endDate) return 0;
        
        const diffMs = endDate.getTime() - startDate.getTime();
        
        switch (unit) {
            case 'hours':
                return diffMs / (1000 * 60 * 60);
            case 'days':
                return diffMs / (1000 * 60 * 60 * 24);
            case 'minutes':
                return diffMs / (1000 * 60);
            default:
                return diffMs / (1000 * 60 * 60);
        }
    }
    
    function getColorFromText(text) {
        if (!text) return '#cccccc';
        
        let hash = 0;
        for (let i = 0; i < text.length; i++) {
            hash = text.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        let h = hash % 360;
        return `hsl(${h}, 70%, 60%)`;
    }
    
    return {
        showError: showError,
        applyCustomAdjustments: applyCustomAdjustments,
        formatDate: formatDate,
        calculateDuration: calculateDuration,
        getColorFromText: getColorFromText
    };
})();