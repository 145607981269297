frappe.provide('frappe.aps.controls');

frappe.aps.controls = (function() {
    function setupNavigationButtons(ctx) {
        $('#btn-hoje').off('click');
        $('#btn-ant-7dias').off('click');
        $('#btn-ant-14dias').off('click');
        $('#btn-prox-7dias').off('click');
        $('#btn-prox-14dias').off('click');
        
        document.getElementById('btn-hoje').addEventListener('click', function () {
            if (!ctx || !ctx.timeline) {
                console.warn("Contexto ou timeline não disponível");
                return;
            }
            
            const today = new Date();
            const start = new Date(today);
            start.setDate(today.getDate());
            start.setHours(0, 0, 0, 0);
    
            const end = new Date(start);
            end.setDate(start.getDate() + 2); 
    
            ctx.timeline.setWindow(start, end);
        });
    
        document.getElementById('btn-ant-7dias').addEventListener('click', function () {
            if (!ctx || !ctx.timeline) return;
            
            const currentWindow = ctx.timeline.getWindow();
            const start = new Date(currentWindow.start);
            const end = new Date(currentWindow.end);
    
            start.setDate(start.getDate() - 7);
            end.setDate(end.getDate() - 7);
    
            ctx.timeline.setWindow(start, end);
        });
    
        document.getElementById('btn-ant-14dias').addEventListener('click', function () {
            if (!ctx || !ctx.timeline) return;
            
            const currentWindow = ctx.timeline.getWindow();
            const start = new Date(currentWindow.start);
            const end = new Date(currentWindow.end);
    
            start.setDate(start.getDate() - 14);
            end.setDate(end.getDate() - 14);
    
            ctx.timeline.setWindow(start, end);
        });    
    
        document.getElementById('btn-prox-7dias').addEventListener('click', function () {
            if (!ctx || !ctx.timeline) return;
            
            const currentWindow = ctx.timeline.getWindow();
            const start = new Date(currentWindow.start);
            const end = new Date(currentWindow.end);
    
            start.setDate(start.getDate() + 7);
            end.setDate(end.getDate() + 7);
    
            ctx.timeline.setWindow(start, end);
        });
    
        document.getElementById('btn-prox-14dias').addEventListener('click', function () {
            if (!ctx || !ctx.timeline) return;
            
            const currentWindow = ctx.timeline.getWindow();
            const start = new Date(currentWindow.start);
            const end = new Date(currentWindow.end);
    
            start.setDate(start.getDate() + 14);
            end.setDate(end.getDate() + 14);
    
            ctx.timeline.setWindow(start, end);
        });
        
        console.log("Botões de navegação configurados");
    }
    
    function setupZoomControls(ctx) {
        $('.zoom-controls').remove();
        
        const zoomControls = document.createElement('div');
        zoomControls.className = 'zoom-controls';
        
        // Removemos position:absolute para ficar na mesma linha
        // e adicionamos margin-left:auto para alinhar à direita
        zoomControls.style.marginLeft = 'auto';
        zoomControls.style.display = 'inline';
        zoomControls.style.alignItems = 'center';
        
        zoomControls.innerHTML = `
            <label for="group-height" style="margin-left: 10px;">Controles de Zoom:</label>
            <button id="btn-zoom-in"      class="btn btn-xs btn-default" title="Ampliar"><i class="fa fa-plus"></i></button>
            <button id="btn-zoom-out"     class="btn btn-xs btn-default" title="Reduzir"><i class="fa fa-minus"></i></button>
            <button id="btn-zoom-reset" class="btn btn-xs btn-default" title="Reset Zoom"><i class="fa fa-refresh"></i></button>
        `;
        document.querySelector('.timeline-controls').appendChild(zoomControls);
    
        $('#btn-zoom-in').off('click');
        $('#btn-zoom-out').off('click');
        $('#btn-zoom-reset').off('click');
        
        document.getElementById('btn-zoom-in').addEventListener('click', function () {
            if (!ctx || !ctx.timeline) return;
            ctx.timeline.zoomIn(0.5);        
            setTimeout(() => frappe.aps.utils.applyCustomAdjustments(ctx), 100);
        });
    
        document.getElementById('btn-zoom-out').addEventListener('click', function () {
            if (!ctx || !ctx.timeline) return;
            ctx.timeline.zoomOut(0.5);
            setTimeout(() => frappe.aps.utils.applyCustomAdjustments(ctx), 100);
        });
    
        document.getElementById('btn-zoom-reset').addEventListener('click', function () {
            if (!ctx || !ctx.timeline) return;
            
            const today = new Date();
            const start = new Date(today);
            start.setDate(today.getDate() - today.getDay());
            start.setHours(0, 0, 0, 0);
    
            const end = new Date(start);
            end.setDate(start.getDate() + 7);
    
            ctx.timeline.setWindow(start, end);
            setTimeout(() => frappe.aps.utils.applyCustomAdjustments(ctx), 100);
        });
        
        console.log("Controles de zoom configurados");
        
    }
    
    function addProgressIndicator() {
        const zoomControls = document.querySelector('.zoom-controls');
        if (zoomControls) {
            // Criar o indicador de progresso
            const progressIndicator = document.createElement('div');
            progressIndicator.id = 'timeline-loading-indicator';
            progressIndicator.className = 'timeline-loading-indicator';
            progressIndicator.style.display = 'inline-block';
            progressIndicator.style.marginLeft = '10px';
            progressIndicator.style.fontWeight = 'bold';
            progressIndicator.innerHTML = 'Carregando: <span id="timeline-progress">0%</span>';
            
            // Adicionar ao zoom controls
            zoomControls.appendChild(progressIndicator);
        }
    }

    function setupHighlightControls(ctx) {
        $('#btn-highlight').remove();
        $('#btn-clear-highlight').remove();
        
        // adicionar botão de destaque
        const highlightButton = document.createElement('button');
        highlightButton.id = 'btn-highlight';
        highlightButton.className = 'btn btn-xs btn-default';
        highlightButton.title = 'Visualizar Dependência de Operações';
        highlightButton.innerHTML = '<i class="fa fa-eye"></i>';
        highlightButton.style.marginLeft = '5px';        
        
        const clearHighlightButton = document.createElement('button');
        clearHighlightButton.id = 'btn-clear-highlight';
        clearHighlightButton.className = 'btn btn-xs btn-default';
        clearHighlightButton.title = 'Limpar Destaques';
        clearHighlightButton.innerHTML = '<i class="fa fa-eye-slash"></i>';
        clearHighlightButton.style.marginLeft = '5px';
        
        const zoomControls = document.querySelector('.zoom-controls');
        if (zoomControls) {
            zoomControls.appendChild(highlightButton);
            zoomControls.appendChild(clearHighlightButton);
            
            $('#btn-highlight').off('click');
            document.getElementById('btn-highlight').addEventListener('click', function () {
                if (ctx) {
                    // Se houver um item selecionado, use a nova função de seleção
                    if (frappe.aps.highlight.getCurrentSelection()) {
                        frappe.aps.highlight.handleSelectedItem(ctx);
                    } else {
                        // Se não houver item selecionado, informar o usuário
                        frappe.show_alert({
                            message: __("Selecione uma operação para visualizar suas dependências."),
                            indicator: 'yellow'
                        }, 3);
                    }
                }
            });


            $('#btn-clear-highlight').off('click');
            
            document.getElementById('btn-clear-highlight').addEventListener('click', function () {
                if (ctx) {
                    frappe.aps.highlight.resetHighlight(ctx);
                }
                if (ctx.arrows.hideAll) {
                    ctx.arrows.hideAll();
                }
            });
            
            console.log("Controles de destaque configurados");
        } else {
            console.warn("Elemento .zoom-controls não encontrado");
        }
    }
    
    function setupDatePicker(ctx) {
        $('.date-picker-container').remove();
        
        const datePickerContainer = document.createElement('span');
        datePickerContainer.className = 'date-picker-container';
        datePickerContainer.style.marginLeft = '20px';
        datePickerContainer.style.alignItems = 'center';
        
        datePickerContainer.innerHTML = `
            <label for="timeline-date-picker" style="margin-right: 5px;">Navegar para a data:</label>
            <div class="date-field-container" style="display: inline-block; width: 120px;"></div>
        `;
        
        const timelineControls = document.querySelector('.timeline-controls');
        if (timelineControls) {
            timelineControls.appendChild(datePickerContainer);
            
            const dateField = frappe.ui.form.make_control({
                parent: datePickerContainer.querySelector('.date-field-container'),
                df: {
                    fieldtype: 'Date',
                    fieldname: 'timeline_date_picker',
                    placeholder: 'Selecione a data',
                    default: frappe.datetime.get_today(),
                    onchange: function() {
                        const selectedDate = dateField.get_value();

                        if (selectedDate && ctx) {
                            goToDate(selectedDate, ctx);
                        } else if (!ctx) {
                            frappe.show_alert({
                                message: __("Timeline não inicializada."),
                                indicator: 'orange'
                            }, 3);
                        } else {
                            frappe.show_alert({
                                message: __("Por favor, selecione uma data válida."),
                                indicator: 'orange'
                            }, 3);
                        }
                    }
                },
            render_input: true
            });

            if (ctx) {
                ctx.dateField = dateField;
            }

            console.log("Seletor de data configurado");
            } else {
                console.warn("Elemento .timeline-controls não encontrado");
            }
        }
          

    function goToDate(dateStr, ctx) {
        try {
            // Converter string de data para objeto Date
            const targetDate = frappe.datetime.str_to_obj(dateStr);

            // Determinar período de visualização (metade da janela atual)
            const currentWindow = ctx.timeline.getWindow();
            const currentRange = currentWindow.end - currentWindow.start;
            const halfRange = currentRange / 2;

            // Definir início e fim da nova janela
            const newStart = new Date(targetDate.getTime() - halfRange);
            const newEnd = new Date(targetDate.getTime() + halfRange);

            // Ir para a nova data
            ctx.timeline.setWindow(newStart, newEnd);

            // Aplicar ajustes depois da mudança
            setTimeout(() => frappe.aps.utils.applyCustomAdjustments(ctx), 100);

            // Mostrar confirmação
            frappe.show_alert({
                message: __(`Timeline ajustada para ${dateStr}`),
                indicator: 'green'
            }, 3);
        } catch (error) {
            console.error("Erro ao navegar para a data:", error);
            frappe.show_alert({
                message: __("Erro ao processar a data selecionada."),
                indicator: 'red'
            }, 3);
        }
    }

    return {
        setupNavigationButtons: setupNavigationButtons,
        setupZoomControls: setupZoomControls,
        setupHighlightControls: setupHighlightControls,
        setupDatePicker: setupDatePicker,
        goToDate: goToDate,
        addProgressIndicator: addProgressIndicator
    };
})();